import { Injectable, NgZone } from '@angular/core';
import { Taller, TipoVehiculoTaller, VehiculoTipo, TallerResponsable, TallerEstacionTrabajo, TallerCompraCupo, HistorialTaller } from '../model/RequestTallerR';
import { ConfigServiceService } from '../config/config-service.service';
import { XPayments, XPaymentsResponse, XPaymentsResponseClave } from '../model/XPayments'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ResponseG } from '../model/responseObserve';
import { CuposSTC, RevisadosResumen } from '../model/Cupos';
import { Pagosach } from '../model/IPagoACH';
import { BitacorsACH } from '../model/BitacorsACH';
declare var require
const Swal = require('sweetalert2');
@Injectable({
  providedIn: 'root'
})
export class TallerServiceService {
  private conversionEncryptOutput: string;  
  private conversionDecryptOutput:string; 
  public XPayments:XPayments;
  public XPaymentsClave:XPayments;
  public ITaller:Taller;
  public ITallerJoin:Taller;
  public RTallers:Taller;
  public DetalleTaller:Taller;
  public TipoVehiculotaller:TipoVehiculoTaller;
  public TipoVehiculotallerJoin=[];
  public vehiculoTipo:VehiculoTipo;
  public vehiculoTipoNoAsignadosNormales:any;
  public vehiculoTipoNoAsignadosCargas:any;
  public Responsable:TallerResponsable;
  public ListResponsable:TallerResponsable;
  public EstacionTrabajo:TallerEstacionTrabajo; 
  public ListaEstacionTrabajo:TallerEstacionTrabajo;
  public CompraCupones: TallerCompraCupo;
  public _bitacorsACH: BitacorsACH;
  public Guardar:boolean = false;
  public Siguiente:boolean = false;
  public ShowCaja:boolean = true;
  public NombreTaller:string;
  public pagandoTC:boolean =false;
  public CountPage=[];
  public totalPage=0;
  public cargandoPag:boolean =false;
  public CantidadRecord:number =0;
  public Historial: HistorialTaller;
  public responseG: ResponseG;
  public cuposSTC: CuposSTC;
  public cargandoCupos: boolean = false;
  public _resumen: RevisadosResumen;
  public _DataACH: Pagosach;
  constructor(public _config:ConfigServiceService,public ngZone:NgZone,private _http:HttpClient, public NavCrtl:Router) {

    this.initInterfas();
    this.InitResponsable();
    this.InitEstacion();
    this.InitCompra();
    this.InitTC();
    this.initHistorial();
    this.initACH();
   }

    SaveTaller(data:Taller){
      this.Guardar=true;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.post(`${this._config.URL_SERVICE_TALLER}/Taller`,data,requestOptions)
                          .subscribe({
                            next:(res:any)=>{
                              this.Guardar=false;
                              this.ngZone.run(()=>{
                                this.success('success','Exito','Taller creado de manera correcta.');
                                this.ITaller.id = res;
                              });
                            },
                            error:(err)=>{
                              this.success('warning','Validación','No se pudo guardar al Taller, intente nuevamente con los campos requeridos completos')
                              this.Guardar=false;
                            },
                            complete:()=>{
                              this.Siguiente=false;
                            }
        });
    }
  
    UpdateTaller(data:Taller){
      this.Guardar=true;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.put(`${this._config.URL_SERVICE_TALLER}/Taller`,data,requestOptions);
  }
  
      UpdateTallerPerfil(data:Taller){
      this.Guardar=true;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.put(`${this._config.URL_SERVICE_TALLER}/Taller/UpdatePerfilTaller`,data,requestOptions);
    }


    UpdateTallerCuposDiarios(token:string,data:any){
      this.Guardar=true;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': token
      });
       
      const requestOptions = { headers: headers };
      return this._http.put(`${this._config.URL_SERVICE_TALLER}/Taller/UpdateNumeroRevisadosDiarios?tallerId=${data.tallerId}&numeroRevisados=${data.numeroRevisados}`,{},requestOptions);
    }


    updateTallerCodigo(data:Taller){
      this.Guardar=true;

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.put(`${this._config.URL_SERVICE_TALLER}/Taller`,data,requestOptions)
                          .subscribe({
                            next:(res:Taller)=>{
                              this._config.TallerActivo = res;
                              this.success('success','Exito','Código generado correctamente.');
                            },
                            error:(err)=>{
                              this.success('warning','Validación','No se pudo generar el Código correctamente.');
                            },
                            complete:()=>{
                             
                            }
        });
    }

    SaveSucursal(data:Taller){
      this.Guardar=true;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.post(`${this._config.URL_SERVICE_TALLER}/Taller`,data,requestOptions)
                          .subscribe({
                            next:(res:any)=>{
                              this.Guardar=false;
                              this.ngZone.run(()=>{
                                this.success('success','Exito','Sucursal creada de manera correcta.');
                                this.ITaller.id = res;
                              });
                            },
                            error:(err)=>{
                              this.success('warning','Validación','No se pudo guardar al Taller, intente nuevamente con los campos requeridos completos')
                              this.Guardar=false;
                            },
                            complete:()=>{
                              this.getSucursales('codigopadre',data.codigoPadre).subscribe({
                                next:(res:Taller)=>{
                                  this.RTallers=res;
                                }
                              });
                              this.Siguiente=true;
                            }
        });
    }

    ValidarCodigo(codigo){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller/token/${codigo}`);
    }

    GuadarTipoVehiculos(data:any){
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };

      this.TipoVehiculotaller.tallerId=data.IdTaller;
      let cantidad = data.Elegida.length;
      let inicio =0;
        data.Elegida.forEach(element => {
          inicio++;
        this.TipoVehiculotaller.vehiculoTipoId=element;
        this._http.post(`${this._config.URL_SERVICE_TALLER}/TallerVehiculo`,this.TipoVehiculotaller,requestOptions).subscribe({
          next:(res)=>{
          },
          error:(error)=>{
          }
        });

        if (inicio ==cantidad ) {
          this.success('success','Exito','Tipos de Autos asignados correctamente.');
          
          this.Siguiente=false;
          this.initInterfas();
          this.NavCrtl.navigate(['Talleres/listado-talleres']);
        }
      });
    }

    saveTipoVehiculos(array:[],idTaller){
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      this.TipoVehiculotaller.creadoPor = this._config.info.email;
      this.TipoVehiculotaller.tallerId = idTaller;
      this.TipoVehiculotaller.fechaCreacion = new Date();
      array.forEach(element => {
      this.TipoVehiculotaller.vehiculoTipoId=element;
      
      this._http.post(`${this._config.URL_SERVICE_TALLER}/TallerVehiculo`,this.TipoVehiculotaller,requestOptions).subscribe({
        next:(res)=>{
          this.GetonetallerJoin(idTaller);
        },
        error:(error)=>{
        }
      });

    });
    this.success('success','Exito','Vehículo Asignado Correctamente.');
    this.ShowCaja=true;
    }

    GuadarTipoVehiculosSucursal(data:any){

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };

      this.TipoVehiculotaller.tallerId=data.IdTaller;
      let cantidad = data.Elegida.length;
      let inicio =0;
        data.Elegida.forEach(element => {
          inicio++;
        this.TipoVehiculotaller.vehiculoTipoId=element;
        this._http.post(`${this._config.URL_SERVICE_TALLER}/TallerVehiculo`,this.TipoVehiculotaller,requestOptions).subscribe({
          next:(res)=>{
          },
          error:(error)=>{
          }
        });

        if (inicio ==cantidad ) {
          this.success('success','Exito','Tipos de Autos asignados correctamente.');
          
          this.Siguiente=false;
          this.ShowCaja =true;
          // inicialiar interfas de taller, péro el id de taller queda igual,
          this.initInterfas();
          this.ITaller.codigoPadre=data.codigoPadre;
          this.NavCrtl.navigate(['/Talleres/Gestionar/'+data.IdTaller+'/Sucursal']);
        }
      });
    }
    getSucursales(llave:string,valor:any){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller/${llave}/${valor}`);
  }

    VerificarValor(llave:string,valor:string){
        return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller/${llave}/${valor}`);
    }

    GetTipoAutos(Id:any){
        return this._http.get(`${this._config.URL_SERVICE_TALLER}/VehiculoTipo/tipo/${Id}`);
    }

    GetAllTaller(){
      this.CountPage=[];
      // let requestOptions = {
       const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      // observe: "response"
      // }observe: 'response'
      const requestOptions = { headers: headers, observe: 'response' };
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller`,{headers,observe: 'response',responseType:'json',reportProgress:true}).subscribe({
          next:(res:any)=>{
            this.totalPage=res.body.totalPages;
            this.RTallers=res.body.tallerData;
            for (let index = 0; index < res.body.totalPages; index++) {
              this.CountPage.push(index+1)
            }
          },
          error:()=>{
            this.success('warning','ECARCHECK','No se pudo cargar el resultado.')
          },
          complete:()=>{

          }
        });
    }


    GetAllTallerPaginate(data:any){
      // let requestOptions = {
       const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      this.CountPage=[];
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller${data}`,{headers,observe: 'response',responseType:'json',reportProgress:true}).subscribe({
          next:(res:any)=>{
            this.totalPage=res.body.totalPages;
            this.RTallers=res.body.tallerData;
            this.CantidadRecord=res.body.totalCount;
            for (let index = 0; index < res.body.totalPages; index++) {
              this.CountPage.push(index+1)
            }
          },
          error:()=>{
            this.success('warning','ECARCHECK','No se pudo cargar el resultado.');
            this.cargandoPag=false;
          },
          complete:()=>{
            this.cargandoPag=false;
          }
        });
    }

    Getonetaller(id){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller/id/${id}`).subscribe({
          next:(res:Taller)=>{
            this.ITaller=res;
          this.NombreTaller = this.ITaller.razonSocial;
       
          
          },
          error:(err)=>{
            this.success('warning','Validación','Intente')
            this.NavCrtl.navigate(['/Panel-control']);
          },
          complete:()=>{
          }
        });
    }

    GetonetallerJoin(id){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller/idtaller/${id}`).subscribe({
          next:(res:Taller)=>{
            this.ITallerJoin =res;

            this.TipoVehiculotallerJoin =this.ITallerJoin.tallerVehiculos;
            
            
          },
          complete:()=>{
          }
        });
    }

    GetonetallerAsincrono(id){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller/id/${id}`).subscribe();
    }

    GetonetallerActivo(id){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller/id/${id}`);
    }

    getNameTaller(id){
        return this._http.get(`${this._config.URL_SERVICE_TALLER}/Taller/id/${id}`).subscribe({
            next:(res:Taller)=>{
              this.NombreTaller=res.razonSocial;
            }
          });
    }

    VerificarValorResponsable(llave:string,valor:string){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerResponsable/${llave}/${valor}`);
    }

    SaveResponsable(data:TallerResponsable){
    this.Guardar=true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Token': this._config.Token
    });
    const requestOptions = { headers: headers };

    return this._http.post(`${this._config.URL_SERVICE_TALLER}/TallerResponsable`,data,requestOptions)
                        .subscribe({
                          next:(res:any)=>{
                            this.Guardar=false;
                            this.ngZone.run(()=>{
                              this.success('success','Exito','Responsable creado correctamente.');
                              this.GetResponsableTaller(data.tallerId);
                            });
                          },
                          error:(err)=>{
                            this.success('warning','Validación','No se pudo guardar la persona responsable, intente nuevamente con los campos requeridos completos')
                            this.Guardar=false;
                          },
                          complete:()=>{
                            this.InitResponsable();
                            this.ShowCaja =true;
                          }
      });
    }

    UpdateResponsable(data:TallerResponsable){
      this.Guardar=true;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      data.usuarioUltimaModificacion = this._config.info.email;
      data.fechaUltimaModificacion = new Date();
      
      return this._http.put(`${this._config.URL_SERVICE_TALLER}/TallerResponsable`,data,requestOptions);
      }


    GetResponsableTaller(id){
    return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerResponsable/tallerId/${id}`).subscribe({
        next:(res:TallerResponsable)=>{
          this.ListResponsable=res;
        }
      });
    }
    GetOneResponsableTaller(id){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerResponsable/id/${id}`).subscribe({
        next:(res:TallerResponsable)=>{
          this.Responsable=res;
        }
      });
    }

    GetOneResponsableTallerSub(id){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerResponsable/id/${id}`);
    }


    GetUbicacionesTaller(id){
    return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerEstacionTrabajo/tallerId/${id}`).subscribe({
        next:(res:TallerEstacionTrabajo)=>{
        
          
          this.ListaEstacionTrabajo=res;
        }
      });
    }

    ValidarEstacionTrabajo(id){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerEstacionTrabajo/tallerId/${id}`);
    }

    SaveUbicacion(data:TallerEstacionTrabajo){
      this.Guardar=true;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.post(`${this._config.URL_SERVICE_TALLER}/TallerEstacionTrabajo`,data,requestOptions)
                          .subscribe({
                            next:(res:any)=>{
                              this.Guardar=false;
                              this.ngZone.run(()=>{
                                this.success('success','Exito','Ubicación creado correctamente.');
                              });
                            },
                            error:(err)=>{
                              this.success('warning','Validación','No se pudo guardar el puesto de trabajo, intente nuevamente con los campos requeridos completos')
                              this.Guardar=false;
                            },
                            complete:()=>{
                              this.GetUbicacionesTaller(data.tallerId)
                              this.InitEstacion();
                              this.ShowCaja =true;
                            }
        });
    }

    UpdateUbicacion(data:TallerEstacionTrabajo){
      this.Guardar=true;
      data.fechaUltimaModificacion = new Date();
      data.usuarioUltimaModificacion = this._config.info.email;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.put(`${this._config.URL_SERVICE_TALLER}/TallerEstacionTrabajo`,data,requestOptions);
    }


    SaveUbicacionPut(data:TallerEstacionTrabajo){
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.put(`${this._config.URL_SERVICE_TALLER}/TallerEstacionTrabajo`,data,requestOptions)
                          .subscribe({
                            next:(res:any)=>{
                              this.ngZone.run(()=>{
                              });
                            },
                            error:(err)=>{
                              this.Guardar=false;
                            },
                            complete:()=>{
                              this.InitEstacion();
                            }
        });
    }

    ValidarCuponesTaller(id){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerCompraCupo/tallerId/${id}`);
    }

    validarCupones(taller:any,tipo:any){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerCompraCupo/tipoCupo/${tipo}, idtaller/${taller}`);
    }

    SaveCompra(data:TallerCompraCupo,TC:any){

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };

        this.Guardar=true;
        let pagado = false;
        this.pagandoTC=true;
        this.XPayments.X_Token=this._config.Token;
        this.XPayments.X_PayBy=this._config.info.email;
        this.XPayments.X_order_key=data.tallerId.toString();
        this.XPayments.x_amount=TC.total.toString();
        this.XPayments.x_address=this._config.TallerActivo.domicilio;
        this.XPayments.x_zip='507';
        this.XPayments.x_Name=TC.nombre;
        this.XPayments.x_card_num=`${TC.primeraCifra}${TC.segundaCifra}${TC.terceraCifra}${TC.cuartaCifra}`;
        this.XPayments.x_exp_date=`${TC.mes}${TC.anio}`;
        this.XPayments.X_OwnerBy = this._config.TallerActivo.razonSocial; // Taller que compra..
        this.XPayments.X_TypeBuy = TC.tipoCompra; // tipo de compra PARTICULAR - TRANSPORTE
        this.XPayments.X_LotBuy = TC.cantidadComprada; // tipo de compra PARTICULAR - TRANSPORTE
        //colocar a descripcion en la variable que ellos propongan
        return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}ServicesPayments`,this.XPayments).subscribe({
          next:(res:XPaymentsResponse)=>{
          if(res.status == 200){
            pagado = true;
            this.pagandoTC=false;
            this.success('success','ECARCHECK','La transacción fue aprobada.');
          }else{
            this.pagandoTC=false;
            pagado=false;
            this.success('warning','ECARCHECK',res.message);
          }
          },
          error:(err)=>{
            pagado=false;
            this.pagandoTC=false;
            this.success('warning','ECARCHECK',err);
          },
          complete:()=>{
            if(pagado == true){
              this._http.post(`${this._config.URL_SERVICE_TALLER}/TallerCompraCupo`,data,requestOptions)
                          .subscribe({
                            next:(res:TallerCompraCupo)=>{
                              this.Guardar=false;
                              this.ngZone.run(()=>{
                                // this.success('success','Exito','Cupos Comprados correctamente.');
                                this.InitCompra();
                                this._config.CuposTaller =res;
                                this.CompraCupones=res;
                                this.NavCrtl.navigate(['/Panel-control']);
                              });
                            },
                            error:(err)=>{
                              this.success('warning','Validación','No se pudo Comprar, intente nuevamente con los campos requeridos completos')
                              this.Guardar=false;
                            },
                            complete:()=>{
                              this.InitResponsable();
                              this.ShowCaja =true;
                            }
                        });
            }
          }
        });
    }

    UpdateCompra(data:TallerCompraCupo,TC:any){
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
        this.Guardar=true; 
        let pagado = false;
        this.pagandoTC=true;
        this.XPayments.X_Token=this._config.Token;
        this.XPayments.X_PayBy=`${this._config.info.email}`;
        this.XPayments.X_order_key=data.tallerId.toString();
        this.XPayments.x_amount=TC.total.toString();
        this.XPayments.x_address=this._config.TallerActivo.domicilio;
        this.XPayments.x_zip='507';
        this.XPayments.x_Name=TC.nombre;
        this.XPayments.x_card_num=`${TC.primeraCifra}${TC.segundaCifra}${TC.terceraCifra}${TC.cuartaCifra}`;
        this.XPayments.x_exp_date=`${TC.mes}${TC.anio}`;
        this.XPayments.X_OwnerBy = this._config.TallerActivo.razonSocial; // Taller que compra..
        this.XPayments.X_TypeBuy = TC.tipoCompra; // tipo de compra PARTICULAR - TRANSPORTE
        this.XPayments.X_LotBuy = TC.cantidadComprada; // tipo de compra PARTICULAR - TRANSPORTE
         //colocar a descripcion en la variable que ellos propongan
        return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}ServicesPayments`,this.XPayments).subscribe({
          next:(res:XPaymentsResponse)=>{
          if(res.status == 200){
            pagado=true;
            this.success('success', 'ECARCHECK', 'La transacción fue aprobada.');
             this.pagandoTC=false;
          }else{
            this.pagandoTC=false;
            pagado=false;
            this.success('warning','ECARCHECK',res.message);
          }
          },
          error:(err)=>{
            pagado=false;
            this.pagandoTC=false;
            this.success('warning','ECARCHECK','Intente nuevamente');
          },
          complete:()=>{
            if(pagado == true){
              this._http.put(`${this._config.URL_SERVICE_TALLER}/TallerCompraCupo`,data,requestOptions)
                                .subscribe({
                                  next:(res:TallerCompraCupo)=>{
                                    this.Guardar=false;
                                    this.ngZone.run(()=>{
                                      this.InitCompra();
                                      this._config.CuposTaller =res;
                                      this.CompraCupones=res;
                                      this.NavCrtl.navigate(['/Panel-control']);
                                    });
                                  },
                                  error:(err)=>{
                                    this.success('warning','Validación','No se pudo Comprar, intente nuevamente con los campos requeridos completos')
                                    this.Guardar=false;
                                  },
                                  complete:()=>{
                                    
                                    this.InitResponsable();
                                    this.ShowCaja =true;
                                    this.pagandoTC=false;
                                    this.InitTC();
                                  }
              });
            }
          }
        })             
 
    }

    GetlinkClave(data:TallerCompraCupo,TC:any,tipado:string){
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
        this.Guardar=true; 
        let pagado = false;
        this.pagandoTC=true;
        this.XPayments.X_Token=this._config.Token;
        this.XPayments.X_PayBy=`${this._config.info.email}`;
        this.XPayments.X_order_key=data.tallerId.toString();
        this.XPayments.x_amount=TC.total.toString();
        this.XPayments.x_address=this._config.TallerActivo.domicilio;
        this.XPayments.x_zip='507';
        this.XPayments.x_Name=this._config.info.email;
        this.XPayments.x_card_num="";
        this.XPayments.x_exp_date="";
        this.XPayments.X_OwnerBy =this._config.TallerActivo.razonSocial;; // Taller que compra..
        this.XPayments.X_TypeBuy = TC.tipoCompra; // tipo de compra PARTICULAR - TRANSPORTE
        this.XPayments.X_LotBuy = TC.cantidadComprada; // tipo de compra PARTICULAR - TRANSPORTE
        this.XPayments.X_ClientSessionId = new Date().getTime().toString();
        this.XPayments.x_type=tipado;

        this.conversionEncryptOutput = CryptoJS.AES.encrypt(JSON.stringify(this.XPayments), 'Panama2019$').toString();  
        this.conversionEncryptOutput.replace('/','_');
        this.conversionEncryptOutput.replace('+','`~`');
        localStorage.setItem('dataPago',this.conversionEncryptOutput);

        this.conversionEncryptOutput='';
        this.conversionEncryptOutput = CryptoJS.AES.encrypt(JSON.stringify(data), 'Panama2019$').toString();  
        this.conversionEncryptOutput.replace('/','_');
        this.conversionEncryptOutput.replace('+','`~`');
        localStorage.setItem('DataC',this.conversionEncryptOutput);

        return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}ServicesPaymentsTD`,this.XPayments).subscribe({
          next:(res:XPaymentsResponseClave)=>{
          if(res.code == "200"){
            pagado=true;
            this.pagandoTC = false;
            
            window.open(res.detail, '_self');
          }else{
            this.pagandoTC=false;
            pagado=false;
            this.success('warning','ECARCHECK',res.detail);
          }
          },
          error:(err)=>{
            this.pagandoTC=false;
            this.success('warning','ECARCHECK','Intente nuevamente');
          },
          complete:()=>{
          }
        });
    }

    // http://localhost:4200/GetPaymentsClave?session_id=3bf2a384-8818-4a93-8aca-2d9e3241f9a3
    ValidTClave(idSession:string){
     
      let datosPagos = localStorage.getItem('dataPago');
      let DatosTaller = localStorage.getItem('DataC');
      if(datosPagos!=null && DatosTaller!=null){
        
        datosPagos.replace('/','_');
        datosPagos.replace('~','`+`');
        datosPagos = CryptoJS.AES.decrypt(datosPagos, 'Panama2019$').toString(CryptoJS.enc.Utf8);

        DatosTaller.replace('/','_');
        DatosTaller.replace('~','`+`');
        DatosTaller = CryptoJS.AES.decrypt(DatosTaller, 'Panama2019$').toString(CryptoJS.enc.Utf8);


        this.XPaymentsClave = JSON.parse(datosPagos);
        this.CompraCupones = JSON.parse(DatosTaller);
          this.XPayments.X_Token=this.XPaymentsClave.X_Token;
          this.XPayments.X_PayBy=`${this.XPaymentsClave.X_PayBy}`;
          this.XPayments.X_order_key=this.XPaymentsClave.X_order_key;
          this.XPayments.x_amount=this.XPaymentsClave.x_amount;
          this.XPayments.x_address=this.XPaymentsClave.x_address
          this.XPayments.x_zip='507';
          this.XPayments.x_Name=this._config.info.email;
          this.XPayments.x_card_num="";
          this.XPayments.x_exp_date="";
          this.XPayments.X_OwnerBy =this.XPaymentsClave.X_OwnerBy; // Taller que compra..
          this.XPayments.X_TypeBuy = this.XPaymentsClave.X_TypeBuy; // tipo de compra PARTICULAR - TRANSPORTE
          this.XPayments.X_LotBuy = this.XPaymentsClave.X_LotBuy; // tipo de compra PARTICULAR - TRANSPORTE
          this.XPayments.X_ClientSessionId = idSession; 

          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Token': this.XPaymentsClave.X_Token
          });
    
          const requestOptions = { headers: headers };
          return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}ValidPayTD`,this.XPayments).subscribe({
            next:(res:XPaymentsResponseClave)=>{
             
            if(res.code == "200"){
              if (this.XPaymentsClave.x_type == "1") {
                this._http.post(`${this._config.URL_SERVICE_TALLER}/TallerCompraCupo`,this.CompraCupones,requestOptions)
                .subscribe({
                  next:(res:TallerCompraCupo)=>{
                    this.Guardar=false;
                    this.ngZone.run(()=>{
                      // this.success('success','Exito','Cupos Comprados correctamente.');
                      this.InitCompra();
                      this._config.CuposTaller =res;
                      this.CompraCupones=res;
                      this.NavCrtl.navigate(['/Panel-control']);
                    });
                  },
                  error:(err)=>{
                    this.success('warning','Validación','No se pudo Comprar, intente nuevamente con los campos requeridos completos ' + res.detail)
                    this.Guardar=false;
                  },
                  complete:()=>{
                    this.InitResponsable();
                    this.ShowCaja =true;
                  }
              });
              }

              if (this.XPaymentsClave.x_type == "2") {
                //update
                this._http.put(`${this._config.URL_SERVICE_TALLER}/TallerCompraCupo`,this.CompraCupones,requestOptions)
                                .subscribe({
                                  next:(res:TallerCompraCupo)=>{
                                    this.Guardar=false;
                                    this.ngZone.run(()=>{
                                      this.InitCompra();
                                      this._config.CuposTaller =res;
                                      this.CompraCupones=res;
                                      this.NavCrtl.navigate(['/Panel-control']);
                                    });
                                  },
                                  error:(err)=>{
                                    this.success('warning','Validación','No se pudo Comprar, intente nuevamente con los campos requeridos completos')
                                    this.Guardar=false;
                                  },
                                  complete:()=>{
                                    
                                    this.InitResponsable();
                                    this.ShowCaja =true;
                                    this.pagandoTC=false;
                                    this.InitTC();
                                  }
              });
              }
            }else{
              this.NavCrtl.navigate(['/Ajustes/Cupones'])
              this.success('warning','ECARCHECK',res.detail);
            }
            },
            error:(err)=>{
              this.NavCrtl.navigate(['/Ajustes/Cupones'])
              this.success('warning','ECARCHECK','Intente nuevamente');
            },
            complete:()=>{
            }
          });
      }else{
        this.success('warning','ECARCHECK','Intente nuevamente, no mantiene una Sesión iniciada.');
        this.NavCrtl.navigate(['/'])
      }
    }

    //one
    GetOneResponsable(llave:string,valor:string){
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerResponsable/${llave}/${valor}`);
    }


    getTallerLike(nameTaller){
      
      let data={
        nameTaller:nameTaller
      }
      return this._http.post(`${this._config.URL_SERVICE_TALLER}/Taller/razonsociallike/${nameTaller}`,data);
    }
    /********************************************HISTORIAL********************************************/
    GuardarHistorial(data:HistorialTaller){
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Token': this._config.Token
      });
      const requestOptions = { headers: headers };
      return this._http.post(`${this._config.URL_SERVICE_TALLER}/TallerHistorial`,data,requestOptions).subscribe({
        next:(res)=>{
        },
        error:(err)=>{
          
        },complete:()=>{

        }
      })
    }

      SaveCupos(data:any){
        this.initG();
      return this._http.post(`${this._config.URL_SERVICE_MANTENIMIENTO}/Api/CargarCuposSTC`,data).subscribe({
        next: (res: ResponseG) => {
          this.responseG = res;
          if (res.codeError == 200) {
            this.success('success', 'ECARCHECK', res.message);
            this.cargandoCupos = false;
            this.GetCuposCargados();
          } else {
            this.success('warning','ECARCHECK',res.message);
          }
        },
        error:(err)=>{
          this.success('warning', 'ECARCHECK', 'Intente nuevamente, No se pudo cargar los cupos.');
          this.cargandoCupos = false;
        },complete:()=>{
          this.cargandoCupos = false;
        }
      })
  }
  


        GetCuposCargados(){
          this.initCuposSTCI();
          this._config.getUserLocal();

          let data = {
            Token:this._config.Token
          }
        return this._http.post(`${this._config.URL_SERVICE_MANTENIMIENTO}/Api/GetCuposSTC`,data).subscribe({
          next: (res: CuposSTC) => {
            this.cuposSTC = res;
            
            if (res.codeError == 200) {
            } else {
              this.success('warning','ECARCHECK',res.message);
            }
          },
          error:(err)=>{
            this.success('warning','ECARCHECK','Intente nuevamente, No se pudo obtener los cupos cargados.');
          },complete:()=>{

          }
        })
    }


      RestablecerCupos(){
          this._config.getUserLocal();
          let data = {
            Token: this._config.Token,
            CreatedBy: this._config.info.email,
          }
          return this._http.post(`${this._config.URL_SERVICE_MANTENIMIENTO}/Api/RestablecerCupos`,data).subscribe({
            next: (res: ResponseG) => { 
              if (res.codeError == 200) {
                this.success('success','ECARCHECK',res.message);
              } else {
                this.success('warning','ECARCHECK',res.message);
              }
            },
            error:(err)=>{
            this.success('warning','ECARCHECK','Intente nuevamente, no se pudo restablecer los cupos.');
            },complete:()=>{
              this.GetCuposCargados();
            }
          })
    }


  getResumenRevisados(data: any) {
    this.initResumenRevisados();
        return this._http.post(`${this._config.URL_SERVICE_MANTENIMIENTO}/Api/ResumenRevisado`,data).subscribe({
          next: (res: RevisadosResumen) => { 
            this._resumen = res;
            
            
            if (res.codeError == 200) {
            } else {
              this.success('warning','ECARCHECK',res.message);
            }
          },
          error: (err) => {
            this.cargandoCupos = false;
              this.success('warning','ECARCHECK','Intente nuevamente, no se pudo obtener el resumen de Revisados.');
          },complete:()=>{
          this.cargandoCupos = false;
          }
        })
  }

        ActualizarCupos(data:any){
          return this._http.post(`${this._config.URL_SERVICE_MANTENIMIENTO}/Api/ResumenRevisadoActualizarCupos`,data).subscribe({
            next: (res:RevisadosResumen) => { 
              if (res.codeError == 200) {
                this.success('success','ECARCHECK',res.message);
              } else {
                this.success('warning','ECARCHECK',res.message);
              }
            },
            error: (err) => {
              this.cargandoCupos = false;
            this.success('warning','ECARCHECK','Intente nuevamente, no se pudo actualizar los cupos.');
            },complete:()=>{
              this.cargandoCupos = false;
            }
          })
  }
  
    /**********************************************MAESTRAS*******************************************/

    GetProvincias(){
      return this._http.get(`${this._config.URL_SERVICE_MAESTRAS}/Provincias`);
    }
    GetOneProvincia(Llaveprovincia){
      let provincia={
        Dato:Llaveprovincia
      }
      return this._http.post(`${this._config.URL_SERVICE_MAESTRAS}/Provincias/getProvinciaByN`,provincia);
    }

    GetDistritos(){
      return this._http.get(`${this._config.URL_SERVICE_MAESTRAS}/Distrito`);
    }

    GetOnedistrito(Llaveprovincia){
      let provincia={
        Dato:Llaveprovincia
      }
      return this._http.post(`${this._config.URL_SERVICE_MAESTRAS}/Distrito/getDistritoByProvincia`,provincia);
    }

    GetCorregimientos(){
      return this._http.get(`${this._config.URL_SERVICE_MAESTRAS}/Corregimiento`);
    }
    
    GetOneCorregimientos(distrito){
      let provincia={
        Dato:distrito,
      }
      return this._http.post(`${this._config.URL_SERVICE_MAESTRAS}/Corregimiento/getCorregimientoByDistrito`,provincia);
    }

    PostACH(data){
      return this._http.post(`${this._config.URL_SERVICE_ACH}SaveSolicitudACH`,data);
  }
  
  VerificarStatus(data){
      return this._http.post(`${this._config.URL_SERVICE_ACH}VerificarStatus`,data);
  }
  
    VerificarAprobado(data){
      return this._http.post(`${this._config.URL_SERVICE_ACH}VerificarAprobado`,data);
  }

  DeleteSolicitudByStatus(data){
      return this._http.post(`${this._config.URL_SERVICE_ACH}DeleteSolicitudByStatus`,data);
  }

  GetBitacoraTalleres(data){
      return this._http.post(`${this._config.URL_SERVICE_ACH}GetBitacoraTalleres`,data);
  }

    GetBitacoraAdmin(data){
      return this._http.post(`${this._config.URL_SERVICE_ACH}GetBitacoraAdmin`,data);
  }


  

    /*************************************************************************************************************** */
 
    deleteTipoCarro(data:any){
      return this._http.delete(`${this._config.URL_SERVICE_TALLER}/TallerVehiculo?tallerId=${data.tallerId}&idvehiculotipo=${data.vehiculoTipoId}`);
    }

    getAutosNoAsignados(data:any){
      this.ShowCaja=true;
      this.initTipoVehiculo();
      return this._http.get(`${this._config.URL_SERVICE_TALLER}/TallerVehiculo/tallerId/${data.tallerId}, tipo/${data.vehiculoTipoId}`).subscribe({
        next:(res:VehiculoTipo)=>{
          if (data.vehiculoTipoId == 1) {
            this.vehiculoTipoNoAsignadosNormales=res;
          } else {
            this.vehiculoTipoNoAsignadosCargas=res;
          }
        },
        error:()=>{
          this.ShowCaja=true;
          this.success('warning','ECARCHECK','Intente Nuevamente')
        },
        complete:()=>{
          if (this.vehiculoTipoNoAsignadosNormales!=null && this.vehiculoTipoNoAsignadosCargas!=null) {
            this.ShowCaja=false;
          }
        }
      })
  }


  Init_BitacorsACH() {
    this._bitacorsACH = {
      statusCode: 0,
      message: '',
      auditoriaACH: [
        {
          auditoriaId:0,
          keySolicitudACH:0,
          comentarios:'',
          accion:'',
          createdBy:'',
          createdDate:new Date(),
        }
      ]
    }
  }

  initResumenRevisados() {
    this._resumen = {
      cantidadRevisados:0,
      cantidadRevisadosEcarcheck:0,
      cantidadRevisadosSTC:0,
      cuposRestanteSTC:0,
      migradaCuposRestanteSTC: 0,
      precioTotalEcarcheckParticular:0,
      precioTotalEcarcheckTransporte:0,
      codeError:0,
      message:''
    }
  }
  initCuposSTCI() {
    this.cuposSTC = {
      codeError:0,
      message:'',
      cantidadMigrados:0,
      cantidadDisponibles:0,
      cantidadParticular:0,
      cantidadTransporte:0,
      cantidadUsados:0,
      cantidadCuposEcarcheck:0,
      cantidadCuposEcarcheckParticular:0,
      cantidadCuposEcarcheckTransporte:0,
      cantidadCuposDisponibleEcarcheckTransporte:0,
      cantidadCuposUsadosEcarcheckTransporte:0,
      cupos: [
        {
        idCupos:0,
        codigoTaller:'',
        cuposParticulares:0,
        cuposTransporte:0,
        totalCupos:0,
        cuposFaltante:0,
        cuposUsados:0,
        nombreTaller:'',
        fechaCarga:new Date(),
        }
      ]
  }
}
  initG() {
    this.responseG = {
      codeError: 0,
      cupos: [],
      message: '',
      resumen:[]
  }
}
    initInterfas(){
      this.ShowCaja =true;
      this.ITaller = {
        id:0,
        fechaCreacion:new Date(),
        creadoPor:this._config.info.name,
        fechaUltimaModificacion:new Date(),
        usuarioUltimaModificacion:'',
        codigo:'',
        tipoIdentificacion:'',
        numeroIdentificacion:'',
        tipo:'',
        codigoPadre:'',
        razonSocial:'',
        provincia:'',
        distrito:'',
        corregimiento:'',
        domicilio:'',
        fechaConstitucion:new Date(),
        telefono:'',
        correo:'',
        nombreRepresentanteLegal:'',
        tipoIdentificacionRepresentanteLegal:'',
        numeroIdentificacionRepresentanteLegal:'',
        numeroContrato:0,
        numeroRevisadosDiariosAutorizados:0,
        metodoCargarImagenes:'APP',
        fechaAutorizado:new Date(),
        observaciones:'',
        estado:'ACTIVO',
        token:this._config.Token,
        tallerCompraCupos:[],
        tallerDenuncias:[],
        tallerEstacionTrabajos:[],
        tallerHistorials:[],
        tallerInspeccions:[],
        tallerResponsables:[],
        tallerVehiculos:[],
        //TokenVerificacion:this._config.Token
      };

      this.TipoVehiculotaller = {
        id:0,
        fechaCreacion:new Date(),
        creadoPor:this._config.info.name,
        fechaUltimaModificacion:new Date(),
        usuarioUltimaModificacion:'',
        tallerId:this.ITaller.id,
        vehiculoTipoId:0,
        estado:'ACTIVO',
      }
      this.vehiculoTipo = {
        id:0,
        fechaCreacion:new Date(),
        creadoPor:this._config.info.name,
        fechaUltimaModificacion:new Date(),
        usuarioUltimaModificacion:'', 
        nombre:'',
        tipo:'',
        estado:'',
        tallerVehiculos:[],
      }
    }

    InitResponsable(){
      this.Responsable = {
        id:0,
        fechaCreacion:new Date(),
        creadoPor:this._config.info.email,
        fechaUltimaModificacion:new Date(),
        usuarioUltimaModificacion:'',
        tallerId:this.ITaller.id,
        tipoDocumentoIdentificacion:'',
        numeroDocumentoIdentificacion:'',
        primerNombre:'',
        segundoNombre:'',
        apellidoPaterno:'',
        apellidoMaterno:'',
        apellidoCasada:'',
        tipo:'',
        usuarioSistema:'',
        observaciones:'',
        estado:''
      }
    }
    InitTC(){
      this.XPayments = {
        x_test_request:'',
        X_order_key:'',
        x_invoice_num:'',
        x_amount:'',
        x_exp_date:'',
        x_address:'',
        x_zip:'',
        x_first_name:'',
        x_last_name:'',
        x_version:'',
        x_delim_data:'',
        x_delim_char:'',
        x_relay_response:'',
        x_type:'',
        x_method:'',
        x_solution_id:'',
        x_login:'',
        x_tran_key:'',
        x_card_num:'',
        x_card_code:'',
        x_month:'',
        x_year:'',
        x_Name:'',
        X_PayBy:'',
        X_Token:'',
        X_Ip:'',
        X_OwnerBy:'',
        X_TypeBuy:'',
        X_LotBuy:'',
        X_ClientSessionId:''
      }

      
    }
    InitEstacion(){
      this.EstacionTrabajo ={
        id:0,
        fechaCreacion:new Date(),
        creadoPor:this._config.info.name,
        fechaUltimaModificacion:new Date(),
        usuarioUltimaModificacion:'',
        tallerId:0,
        tipoDispositivo:'',
        ipDispositivo:'',
        nombreDispositivo:'',
        ipCamara:'',
        estado:'ACTIVO',
        longitud:0,
        latitud:0,
        radio:100,
      }
    }
    InitCompra(){
      this.CompraCupones = {
        id:0,
        fechaCreacion:new Date(),
        creadoPor:this._config.info.email,
        fechaUltimaModificacion:new Date(),
        usuarioUltimaModificacion:'',
        tallerId:0,
        cantidad:0,
        usados:0,
        disponibles:0,
      };
    }
    initHistorial(){
      this.Historial ={
        id:                        0,
        fechaCreacion:             new Date(),
        creadoPor:                 '',
        fechaUltimaModificacion:   new Date(),
        usuarioUltimaModificacion: '',
        tallerId:                  0,
        accion:                    '',
        valorAccion:               '',
      }
    }

    initTipoVehiculo(){
      this.vehiculoTipoNoAsignadosNormales = {
        id:0,
        fechaCreacion:new Date(),
        creadoPor:this._config.info.name,
        fechaUltimaModificacion:new Date(),
        usuarioUltimaModificacion:'', 
        nombre:'',
        tipo:'',
        estado:'',
        tallerVehiculos:[],
      }

      this.vehiculoTipoNoAsignadosCargas = {
        id:0,
        fechaCreacion:new Date(),
        creadoPor:this._config.info.name,
        fechaUltimaModificacion:new Date(),
        usuarioUltimaModificacion:'', 
        nombre:'',
        tipo:'',
        estado:'',
        tallerVehiculos:[],
      }
  }
  
  initACH() {
    this._DataACH = {
      NroTransaccion:'',
      FechaTransaccion:new Date(),
      BancoEmisor:'',
      MontoTransaccion:'',
      Documento:'',
      TipoCupo:0,
      IdTaller:0,
      Token: '',
      CodigoTaller: '',
      TallerName:''
    }
  }
    success(type:string,title:string,text:string) {
      Swal.fire(
        title,
        text,
        type
      );
    }
}
